























import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource, I18nLocale } from '@/types';
import { getResolvableLocaleLocation } from '@/router';

interface VuexBindings {
  detect: (idOrVanity: string) => ApiResource.Business | undefined,
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
      businesses: 'business/List',
    }),
    VANITY(): string {
      return this.$route.params.VANITY;
    },
    business(): ApiResource.Business | undefined {
      // Fallback to finding by ID automatically
      return this.detect(this.VANITY);
    },
  },
  watch: {
    '$route.params.VANITY': {
      immediate: true,
      async handler(VANITY) {
        if (Number.isInteger(VANITY) && this.businesses.length > 0) {
          console.debug('[Dashboard]', '[VANITY]', 'int && has');
          this.$router.replace({
            params: {
              VANITY: this.businesses[VANITY].vanity,
            },
          });
          return;
        }

        // Load if not yet loaded
        if (!this.business) await this.$store.dispatch('business/List');

        if (Number.isInteger(VANITY) && this.businesses.length > 0) {
          console.debug('[Dashboard]', '[VANITY]', 'int && has (after load)');
          this.$router.replace({
            params: {
              VANITY: this.businesses[VANITY].vanity,
            },
          });
          return;
        }

        if (/^\d+$/.test(VANITY.toString())
        && parseInt(VANITY, 10) < 100
        && this.businesses.length >= parseInt(VANITY, 10) + 1) {
          console.debug('[Dashboard]', '[VANITY]', 'numeric string && has');
          this.$router.replace({
            params: {
              VANITY: this.businesses[parseInt(VANITY, 10)].vanity,
            },
          });
          return;
        }

        // eslint-disable-next-line eqeqeq
        if (VANITY == false && this.businesses.length > 0) {
          console.debug('[Dashboard]', '[VANITY]', 'falsey && has');
          this.$router.replace({
            params: {
              VANITY: this.businesses[0].vanity,
            },
          });
          return;
        }

        // If NotFound: Redirect to /create
        if (!this.business) {
          console.debug('[Dashboard]', '[VANITY]', 'Not found');
          this.redirectToCreate();
          return;
        }

        // Replace business ID with vanity automatically
        if (VANITY === this.business.id && VANITY !== this.business.vanity) {
          console.debug('[Dashboard]', '[VANITY]', 'Found by ID');
          this.$router.replace({
            name: this.$route.name || undefined,
            params: {
              VANITY: this.business.vanity,
            },
            query: this.$route.query,
            hash: this.$route.hash,
          });
          return;
        }

        this.$store.dispatch('business/Find', { BUSINESS_ID: this.business.id }); // Refresh Business
        this.$store.dispatch('business/GetAccount', { BUSINESS_ID: this.business.id });
      },
    },
  },
  methods: {
    redirectToCreate(): void {
      return void this.$router.push(getResolvableLocaleLocation(this.$router, {
        name: 'create',
      }, this.$root.$i18n.locale as I18nLocale));
    },
  },
  meta() {
    return {
      titleTemplate: (chunk: string) => {
        const suffix = `@${this.$route.params.VANITY} / Auftrag.app`;
        return chunk ? `${chunk} / ${suffix}` : suffix;
      },
    };
  },
});
