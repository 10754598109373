










import Vue from 'vue';

export default Vue.extend({
  props: {
    businessId: {
      required: true,
      type: String,
    },
    commissionId: {
      required: true,
      type: String,
    },
    rejectReason: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      error: null as Error | null,
    };
  },
  watch: {
    error: {
      immediate: false,
      handler(e) {
        if (!e) return;

        this.$toast({
          message: e.message,
          classes: 'is-danger',
          position: 'top',
        });
      },
    },
  },
  methods: {
    async reject() {
      this.loading = true;

      await this.$store.dispatch('business/commission/Reject', {
        BUSINESS_ID: this.businessId,
        COMMISSION_ID: this.commissionId,
        form: {
          reason: this.rejectReason,
        },
      }).catch((e) => { this.error = e; });

      this.loading = false;

      this.$emit('request-rejected', true);
    },
  },
});
