


















































































































































































































































































































































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import AcceptButton from '@/components/commission/AcceptButton.vue';
import MediaCommission from '@/components/media/Commission.vue';
import RejectButton from '@/components/commission/RejectButton.vue';
import ShareLinks from '@/components/dashboard/ShareLinks.vue';
import WaitlistReceiveButton from '@/components/dashboard/WaitlistReceiveButton.vue';

interface VuexBindings {
  detect: (idOrVanity: string) => ApiResource.Business,
  list: (b: string) => ApiResource.Commission[],
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    AcceptButton,
    RejectButton,
    ShareLinks,
    MediaCommission,
    WaitlistReceiveButton,
  },
  props: {
    businessVanity: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      requested: {
        error: null,
        loading: false,
      },
      active: {
        error: null,
        loading: false,
      },
      pendingAndComplete: {
        error: null,
        loading: false,
      },
      idle: {
        error: null,
        loading: false,
      },
      quickRejectReason: null,
    };
  },
  computed: {
    ...mapGetters({
      list: 'business/commission/List',
      detect: 'business/Detect',
    }),
    VANITY(): string {
      return this.businessVanity;
    },
    business(): ApiResource.Business {
      return this.detect(this.businessVanity);
    },
    commissions(): ApiResource.Commission[] {
      return this.list(this.business.id);
    },
    requestedCommissions(): ApiResource.Commission[] {
      return this.commissions.filter((c) => c.status === 'requested');
    },
    showActiveCommissions(): boolean {
      return this.activeCommissions.length > 0 || this.active.error !== null;
    },
    activeCommissions(): ApiResource.Commission[] {
      return this.commissions.filter((c) => c.status === 'active');
    },
    showPendingCommissions(): boolean {
      return this.pendingCommissions.length > 0 || this.pendingAndComplete.error !== null;
    },
    pendingCommissions(): ApiResource.Commission[] {
      return this.commissions.filter((c) => c.status === 'pending');
    },
    showCompleteCommissions(): boolean {
      return this.completeCommissions.length > 0 || this.pendingAndComplete.error !== null;
    },
    completeCommissions(): ApiResource.Commission[] {
      return this.commissions.filter((c) => c.status === 'complete');
    },
    showIdleCommissions(): boolean {
      return this.idleCommissions.length > 0 || this.idle.error !== null;
    },
    idleCommissions(): ApiResource.Commission[] {
      return this.commissions.filter((c) => c.status === 'idle');
    },
    rejectReasonOptions() {
      const res: any[] = [];

      const REJECT_REASONS = [
        'budget',
        'busy',
        'other',
        'scope',
      ];

      const REPORT_REASONS = [
        'terms',
        'spam',
      ];

      REJECT_REASONS.concat(REPORT_REASONS).forEach((reason) => {
        res.push({
          value: reason,
          text: this.$t(`reason.${reason}`),
        });
      });

      return res;
    },
  },
  watch: {
    businessVanity: {
      immediate: true,
      async handler() {
        this.fetchCommissions();
      },
    },
  },
  methods: {
    handleError(e: Error) {
      this.$toast({
        message: e.message,
        classes: 'is-danger',
        position: 'top',
      });
    },
    async refreshBusiness() {
      await this.$store.dispatch('business/Find', {
        BUSINESS_ID: this.business.id,
      });
    },
    async fetchCommissions(limit = 20, offset = 0) {
      this.loading = true;

      await this.$store.dispatch('business/commission/List', {
        BUSINESS_ID: this.business.id,
        query: {
          status: ['requested', 'active', 'pending', 'idle', 'complete'],
          limit,
          offset,
          order: 'updated_at',
          direction: 'desc',
        },
      })
        .then(async ({ data }) => {
          if (data.length === limit) await this.fetchCommissions(limit, limit + offset);
        })
        .catch(this.handleError);

      this.loading = false;
    },
  },
});
