























import Vue from 'vue';

export default Vue.extend({
  props: {
    businessId: {
      type: String,
      required: true,
    },
    businessVanity: {
      type: String,
      required: true,
    },
  },
  computed: {
    profileUrl(): string {
      return `${process.env.VUE_APP_BASE_URL}/maker/${this.businessVanity}`;
    },
    profilePermalink(): string {
      return `${process.env.VUE_APP_BASE_URL}/maker?id=${this.businessId}`;
    },
    profileShortUrl(): string {
      return `https://auftr.ag/${this.businessVanity}`;
    },
  },
});
