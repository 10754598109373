










import Vue from 'vue';

export default Vue.extend({
  props: {
    businessId: {
      type: String,
      required: true,
    },
    receiveCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async receive() {
      this.loading = true;

      await this.$store.dispatch('business/ReceiveWaitlisted', {
        BUSINESS_ID: this.businessId,
        form: { count: this.receiveCount },
      })
        .catch((e) => {
          this.$toast({
            message: e.message,
            classes: 'is-danger',
            position: 'top',
          });
        });

      this.loading = false;

      this.$emit('requests-received', true);
    },
  },
});
